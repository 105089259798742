body {
  background-color: #f7f3f5;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  align-items: center;
  justify-content: center;
  background-color: #f7f3f5;
  background-image: url('./bg-pattern-grey.png');
  background-position: center;
  background-size: 100px;
  gap: 40px;
}

.icon {
  width: 125px;
  border-radius: 15px;
}

.logo {
  width: 200px;
}

.coming-soon {
  font-size: 24px;
}

.subtitle {
  font-weight: 300;
  color: #777;
  max-width: 300px;
  text-align: center;
}

a:link, a:visited, a:hover, a:active {
  color: #d33343;
  text-decoration: none;
}

.support p {
  padding: 0px 50px;
  max-width: 370px;
  text-align: center;
  line-height: 22px;
}

.download-logos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-logos img {
  cursor: pointer;
}

img.apple-download {
  width: 150px;
}

img.google-download {
  width: 172px;
}